import styles from "./staffImportTableBody.module.scss";
import { useFormikContext } from "formik";
import { useStores } from "stores";
import { useEffect, useState } from "react";
import { append, identity, times, without } from "ramda";
import { observer } from "mobx-react-lite";
import {
  StaffImportTableForFormik,
  StaffImportTableType,
  StaffStatus
} from "stores/StaffModule/types/StaffImportTable";
import Checkbox from "shared/ui/Inputs/Checkbox";
import { Input } from "shared/ui/Inputs/Input";
import Textarea from "shared/ui/Inputs/Textarea";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import SelectMultiTable from "shared/ui/TableInputs/SelectMultiTable";
import { PhoneInput } from "shared/ui/Inputs/PhoneInput";
import StaffImportTableError from "../StaffImportTableError";
import NumberFormat from "react-number-format";
import StaffImportTableBodyStatus from "./StaffImportTableBodyStatus";
import StaffImportTableBodyDuplicateError from "./StaffImportTableBodyDuplicateError";
import { classNames } from "shared/utils/helpers/classNames";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getKeys } from "shared/utils/helpers/getKeys";
import StaffImportSelect from "./StaffImportSelect";

const duplicateFields = ["snils"];
const isPhoneField = (title: string) => title.startsWith("phone_");

const formatDict = {
  snils: "###-###-### ##",
  inn: "############",
  pasp_code: "###-###"
};

const StaffImportTableBody = () => {
  const { staffImportStore, menuStore } = useStores();
  const [openedListName, setOpenedListName] = useState("");
  const [isDateHidden, setIsDateHidden] = useState([]);

  const {
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    initialValues,
    errors,
    validateForm,
    setFieldTouched
  } = useFormikContext<StaffImportTableForFormik>();

  const changeOpenedWindows = (name: string) => {
    setOpenedListName(openedListName === name ? "" : name);
  };

  useEffect(() => {
    if (values.edu_date_start_all_visible) {
      setIsDateHidden([]);
    } else {
      setIsDateHidden(times(identity, values.staff.length));
    }
  }, [values.edu_date_start_all_visible]);

  const handleEduChange = (ind) => (e) => {
    if (!e.target.checked) {
      setFieldValue(`staff.${ind}.edu_date_start`, "");
      setIsDateHidden(append(ind));
    } else {
      setIsDateHidden(without([ind]));
    }
  };

  // костыль для обновления валидации, пока не разберусь как лучше реализовать.
  useEffect(() => {
    validateForm();
  }, [errors.staff]);

  return (
    <>
      {values["staff"].map((row, ind) => (
        <tr
          key={ind}
          className={classNames(styles.tableInfo, {
            [styles.greyRow]: ind % 2
          })}
        >
          <td data-no-hover>
            <p>{ind + 1}</p>
          </td>

          {staffImportStore.titles.map((title) => {
            // Проверяем, есть ли дубль БД для этого поля
            const isDbDuplicate =
              staffImportStore.dbDuplicateColsResponse[ind]?.includes(title);
            // Проверяем, есть ли внутренний дубль для этого поля
            const isInnerDuplicate =
              staffImportStore.innerDuplicateColsResponse[ind]?.includes(title);

            // получаем красную ячейку после ошибки валидации или ошибки дубля
            const getErrorCell = () => {
              const hasError =
                errors["staff"]?.[ind]?.[title] ||
                isDbDuplicate ||
                isInnerDuplicate;
              return hasError ? styles.errorCell : "";
            };

            // получаем желтую ячейку после изменений начальных значений
            const getModifiedCell = () => {
              const isModified =
                initialValues["staff"][ind][title]?.toString() !==
                  row[title]?.toString() && !getErrorCell();
              return isModified ? styles.modifiedСell : "";
            };

            // Вспомогательная функция для установки статуса строки и значения чекбокса "select"
            const setRowStatus = (
              index: number,
              status: StaffStatus,
              selectValue = 0
            ) => {
              // Устанавливаем статус строки в сторе
              staffImportStore.setStaffStatus(status, index);
              setFieldValue(`staff.${index}.select`, selectValue);
            };

            // Проверяем, есть ли у строки какие-либо ошибки, дубли или некорректные поля.
            const isRowClean = (currentIndex: number) => {
              const hasDbDuplicates = Boolean(
                staffImportStore.dbDuplicateColsResponse[currentIndex]?.length
              );
              const hasInnerDuplicates = Boolean(
                staffImportStore.innerDuplicateColsResponse[currentIndex]
                  ?.length
              );
              const hasIncorrectFields = Boolean(
                getKeys(
                  staffImportStore.verificationResponse[currentIndex]?.[
                    "incorrect_cols"
                  ] || {}
                ).length
              );

              return (
                !hasDbDuplicates && !hasInnerDuplicates && !hasIncorrectFields
              );
            };

            // Помощник для пометки строки как дубликат.
            const setDuplicateForRow = (
              title: string,
              rowIndex: number,
              hasDuplicate: boolean,
              isInner: boolean
            ) => {
              if (isInner) {
                updateInnerDuplicateColsResponse(title, rowIndex, hasDuplicate);
              } else {
                updateDbDuplicateColsResponse(title, rowIndex, hasDuplicate);
              }

              if (hasDuplicate) {
                setRowStatus(rowIndex, "incorrectDouble");
              } else if (isRowClean(rowIndex)) {
                setRowStatus(rowIndex, "verificationRequired");
              }
            };

            // Добавляет или удаляет поле из dbDuplicateColsResponse для данной строки.
            const updateDbDuplicateColsResponse = (
              title: string,
              currentIndex: number,
              isDuplicateFound: boolean
            ) => {
              let updatedCols =
                staffImportStore.dbDuplicateColsResponse[currentIndex] || [];

              if (isDuplicateFound) {
                if (!updatedCols.includes(title)) {
                  updatedCols = [...updatedCols, title];
                }
              } else {
                updatedCols = updatedCols.filter((col) => col !== title);
              }

              staffImportStore.setDbDuplicateColsResponse(
                currentIndex,
                updatedCols
              );
            };

            // Добавляет или удаляет поле из innerDuplicateColsResponse для данной строки.
            const updateInnerDuplicateColsResponse = (
              title: string,
              currentIndex: number,
              isDuplicateFound: boolean
            ) => {
              let updatedCols =
                staffImportStore.innerDuplicateColsResponse[currentIndex] || [];

              if (isDuplicateFound) {
                // Если поле еще не в списке дублей, добавляем его
                if (!updatedCols.includes(title)) {
                  updatedCols = [...updatedCols, title];
                }
              } else {
                // Если дубликата больше нет, удаляем поле из списка
                updatedCols = updatedCols.filter((col) => col !== title);
              }

              staffImportStore.setInnerDuplicateColsResponse(
                currentIndex,
                updatedCols
              );
            };

            // Массив всех телефонных полей для удобства проверки дублей между ними.
            const phoneFields = staffImportStore.titles.filter((t) =>
              isPhoneField(t)
            );
            // Проверяет, является ли значение дубликатом для полей телефонов
            const isDuplicatePhone = (
              value: string,
              currentIndex: number,
              staffData: StaffImportTableType[]
            ) => {
              const trimmedValue = value.trim();
              if (trimmedValue === "") return false;

              let duplicateFound = false;

              // Проверка дублирования между строками
              staffData.some((row, index: number) => {
                if (index === currentIndex) return false;

                phoneFields.some((phoneField) => {
                  const phoneVal = String(row[phoneField]).trim();
                  if (phoneVal === trimmedValue && phoneVal !== "") {
                    duplicateFound = true;
                    return true; // Прерываем внутренний цикл
                  }
                  return false;
                });

                if (duplicateFound) return true;
                return false;
              });

              // Проверка дублирования внутри той же строки
              const currentRow = staffData[currentIndex];
              phoneFields.some((phoneField) => {
                if (phoneField !== title) {
                  const otherVal = String(currentRow[phoneField]).trim();
                  if (otherVal === trimmedValue && otherVal !== "") {
                    duplicateFound = true;
                    return true; // Прерываем
                  }
                }
                return false;
              });

              return duplicateFound;
            };

            // Проверяет, является ли значение дубликатом для полей (ИНН, СНИЛС, паспорт)
            const isDuplicateUniqueField = (
              title: string,
              value: string,
              currentIndex: number,
              staffData: StaffImportTableType[]
            ) => {
              const trimmedValue = value.trim();
              if (trimmedValue === "") return false;

              return staffData.some((row, index: number) => {
                if (index === currentIndex) return false;
                const fieldVal = String(row[title]).trim();
                return fieldVal === trimmedValue && fieldVal !== "";
              });
            };

            // Проверяет, является ли значение дубликатом.
            const isDuplicate = (
              title: string,
              value: string,
              currentIndex: number,
              staffData: StaffImportTableType[]
            ) => {
              const trimmedValue = value.trim();

              if (trimmedValue === "") return false;

              if (isPhoneField(title)) {
                return isDuplicatePhone(value, currentIndex, staffData);
              }

              if (duplicateFields.includes(title)) {
                return isDuplicateUniqueField(
                  title,
                  value,
                  currentIndex,
                  staffData
                );
              }

              return false;
            };

            // Обновляет статус дубликатов для телефонных полей.
            const updatePhoneDuplicates = (
              staffData: StaffImportTableType[]
            ) => {
              const phoneMap: Record<
                string,
                { rowIndex: number; phoneField: string }[]
              > = {};

              staffData.forEach((row, rowIndex: number) => {
                staffImportStore.titles
                  .filter(isPhoneField)
                  .forEach((phoneField) => {
                    const phone = String(row[phoneField]).trim();
                    if (phone) {
                      if (!phoneMap[phone]) {
                        phoneMap[phone] = [];
                      }
                      phoneMap[phone].push({ rowIndex, phoneField });
                    }
                  });
              });

              const affectedPhones = new Set<string>();
              staffImportStore.changedPhoneFields.forEach(
                ({ oldValue, newValue }) => {
                  if (oldValue) affectedPhones.add(oldValue);
                  if (newValue) affectedPhones.add(newValue);
                }
              );

              affectedPhones.forEach((phone: string) => {
                const occurrences = phoneMap[phone];
                if (occurrences) {
                  const isDuplicate = occurrences.length > 1;

                  occurrences.forEach(({ rowIndex, phoneField }) => {
                    setDuplicateForRow(phoneField, rowIndex, isDuplicate, true);
                  });
                }
              });
            };

            // Обновляет статус строки при обнаружении или исчезновении дублей.
            const handleDuplicateStatus = (
              title: string,
              value: string,
              currentIndex: number,
              trimmedValue: string,
              updatedStaff: StaffImportTableType[]
            ) => {
              setFieldValue(`staff.${currentIndex}.${title}`, value, false);

              const foundDuplicate = isDuplicate(
                title,
                value,
                currentIndex,
                updatedStaff
              );

              if (foundDuplicate) {
                setDuplicateForRow(title, currentIndex, true, true);

                if (!isPhoneField(title)) {
                  updatedStaff.forEach((row, index: number) => {
                    const cellValue = String(row[title]).trim();
                    if (cellValue === trimmedValue && cellValue !== "") {
                      setDuplicateForRow(title, index, true, true);
                    }
                  });
                }
              } else {
                if (!isPhoneField(title)) {
                  updatedStaff.forEach((row, index: number) => {
                    const cellValue = String(row[title]).trim();
                    if (cellValue === trimmedValue && cellValue !== "") {
                      setDuplicateForRow(title, index, false, true);
                    }
                  });
                }

                if (isPhoneField(title)) {
                  // Удаляем дубликат для телефонного поля
                  setDuplicateForRow(title, currentIndex, false, true);
                } else {
                  updateInnerDuplicateColsResponse(title, currentIndex, false);
                }

                const rowsWithThisDuplicate = getEntries(
                  staffImportStore.innerDuplicateColsResponse
                )
                  .filter(([, cols]) => cols.includes(title))
                  .map(([rowIndex]) => Number(rowIndex));

                rowsWithThisDuplicate.forEach((rowIndex) => {
                  const rowValue = String(updatedStaff[rowIndex][title]).trim();
                  const stillDuplicate = isDuplicate(
                    title,
                    rowValue,
                    rowIndex,
                    updatedStaff
                  );

                  if (!stillDuplicate) {
                    updateInnerDuplicateColsResponse(title, rowIndex, false);

                    const stillHasInnerDuplicates =
                      staffImportStore.innerDuplicateColsResponse[rowIndex]
                        ?.length;
                    const stillHasDbDuplicates =
                      staffImportStore.dbDuplicateColsResponse[rowIndex]
                        ?.length;

                    if (
                      !stillHasInnerDuplicates &&
                      !stillHasDbDuplicates &&
                      staffImportStore.staffStatus[rowIndex] ===
                        "incorrectDouble"
                    ) {
                      setRowStatus(rowIndex, "verificationRequired");
                    }
                  }
                });
              }

              if (isPhoneField(title)) {
                updatePhoneDuplicates(updatedStaff);
              }
              if (staffImportStore.changedPhoneFields.length > 0) {
                staffImportStore.clearChangedPhoneFields();
              }
            };

            // Обрабатывает ответ верификации и обновляет статусы дублей для текущей строки сотрудника.
            const handleVerificationResponse = (
              title: string,
              value: string,
              currentIndex: number
            ) => {
              const verification =
                staffImportStore.verificationResponse[currentIndex];
              const isValueChanged = verification?.[title] !== value;

              // Если значение изменилось, сразу пересчитываем статус
              if (isValueChanged) {
                const updatedStaff = [...values.staff];
                updatedStaff[currentIndex] = {
                  ...updatedStaff[currentIndex],
                  [title]: value
                };

                handleDuplicateStatus(
                  title,
                  value,
                  currentIndex,
                  value.trim(),
                  updatedStaff
                );
              }

              // Если строка чистая, устанавливаем статус verificationRequired
              if (isRowClean(currentIndex)) {
                setRowStatus(currentIndex, "verificationRequired");
              }

              // Если бэк не approve, управляем дубликатами в dbDuplicateColsResponse и innerDuplicateColsResponse ТОЛЬКО для дублирующих полей
              if (
                verification &&
                !verification.approve &&
                (duplicateFields.includes(title) || isPhoneField(title))
              ) {
                // Проверяем, является ли текущее значение дублем БД
                const isDbDuplicate =
                  verification.doubles && verification.doubles[title];

                // Проверяем, является ли текущее значение внутренним дублем
                const isInnerDuplicate =
                  verification.inner_doubles &&
                  verification.inner_doubles[title];

                // Если значение изменилось и был дубликат БД, удаляем дубль ТОЛЬКО для текущей строки из dbDuplicateColsResponse
                if (isValueChanged && isDbDuplicate) {
                  const filtered = staffImportStore.dbDuplicateColsResponse[
                    currentIndex
                  ]?.filter((col) => col !== title);
                  staffImportStore.setDbDuplicateColsResponse(
                    currentIndex,
                    filtered || []
                  );
                }

                // Если значение не изменилось и это дубль БД, добавляем это поле в список дублей в dbDuplicateColsResponse
                if (!isValueChanged && isDbDuplicate) {
                  const updatedCols =
                    staffImportStore.dbDuplicateColsResponse[currentIndex] ||
                    [];
                  if (!updatedCols.includes(title)) {
                    updatedCols.push(title);
                  }
                  staffImportStore.setDbDuplicateColsResponse(
                    currentIndex,
                    updatedCols
                  );
                }

                // Если значение изменилось и был внутренний дубль, удаляем дубль ТОЛЬКО для текущей строки из innerDuplicateColsResponse
                if (isValueChanged && isInnerDuplicate) {
                  const filtered = staffImportStore.innerDuplicateColsResponse[
                    currentIndex
                  ]?.filter((col) => col !== title);
                  staffImportStore.setInnerDuplicateColsResponse(
                    currentIndex,
                    filtered || []
                  );
                }

                // Если значение не изменилось и это внутренний дубль, добавляем это поле в список дублей в innerDuplicateColsResponse
                if (!isValueChanged && isInnerDuplicate) {
                  const updatedCols =
                    staffImportStore.innerDuplicateColsResponse[currentIndex] ||
                    [];
                  if (!updatedCols.includes(title)) {
                    updatedCols.push(title);
                  }
                  staffImportStore.setInnerDuplicateColsResponse(
                    currentIndex,
                    updatedCols
                  );
                }

                // Проверяем наличие любых дублей после изменений
                const stillHasDbDuplicates = Boolean(
                  staffImportStore.dbDuplicateColsResponse[currentIndex]?.length
                );
                const stillHasInnerDuplicates = Boolean(
                  staffImportStore.innerDuplicateColsResponse[currentIndex]
                    ?.length
                );

                // Если дубли (любые) остались — incorrectDouble, иначе verificationRequired
                setRowStatus(
                  currentIndex,
                  stillHasDbDuplicates || stillHasInnerDuplicates
                    ? "incorrectDouble"
                    : "verificationRequired"
                );
              }

              // Если уже incorrectDouble, выходим без изменений
              if (
                staffImportStore.staffStatus[currentIndex] === "incorrectDouble"
              ) {
                return;
              }
            };

            // Функция, вызываемая при изменении значения поля.
            const setDuplicateError = (
              title: string,
              value: string,
              currentIndex: number
            ) => {
              const trimmedValue = value.trim();

              if (isPhoneField(title)) {
                const oldValue = String(
                  values.staff[currentIndex][title]
                ).trim();
                staffImportStore.addChangedPhoneField(
                  currentIndex,
                  title,
                  oldValue,
                  value
                );
              }

              setFieldValue(`staff.${currentIndex}.${title}`, value, false);
              staffImportStore.updateStaffField(currentIndex, title, value);

              const updatedStaff = [...values.staff];
              updatedStaff[currentIndex] = {
                ...updatedStaff[currentIndex],
                [title]: value
              };
              if (duplicateFields.includes(title) || isPhoneField(title)) {
                handleDuplicateStatus(
                  title,
                  value,
                  currentIndex,
                  trimmedValue,
                  updatedStaff
                );

                handleVerificationResponse(title, value, currentIndex);
              } else {
                setRowStatus(currentIndex, "verificationRequired");
              }
            };

            if (title !== "status" && staffImportStore.selects?.[title]) {
              switch (title) {
                case "company":
                  return (
                    <SelectMultiTable
                      key={title}
                      name={`staff.${ind}.${title}`}
                      options={staffImportStore.selects?.[title] || {}}
                      valueName="id"
                      className={{ scrollboard: styles.selectMultiPopper }}
                    />
                  );
                default:
                  return (
                    <StaffImportSelect
                      key={title}
                      name={`staff.${ind}.${title}`}
                      title={title}
                    />
                  );
              }
            }

            switch (title) {
              case "select":
                return (
                  <td key={title}>
                    <div className={styles.selectCell}>
                      <Checkbox
                        name={`staff.${ind}.${title}`}
                        id={`staff.${ind}.${title}`}
                        disabled={
                          staffImportStore.staffStatus[ind] !== "correct"
                        }
                      />
                    </div>
                  </td>
                );
              case "status":
                return (
                  <StaffImportTableBodyStatus
                    index={ind}
                    key={title}
                    className={classNames(styles.whiteCell, {
                      [styles.greyCell]: ind % 2
                    })}
                  />
                );
              case "holding_edu":
                return (
                  <td key={title} className={getModifiedCell()}>
                    <div className={styles.selectCell}>
                      <Checkbox
                        name={`staff.${ind}.${title}`}
                        id={`staff.${ind}.${title}`}
                        onChange={handleEduChange(ind)}
                      />
                    </div>
                  </td>
                );
              case "edu_date_start":
                return (
                  <td
                    key={title}
                    className={`${getModifiedCell()} ${getErrorCell()}`}
                    {...renderHover(isDateHidden, ind)}
                  >
                    {isDateHidden.includes(ind) ? null : (
                      <DatePickerField
                        name={`staff.${ind}.${title}`}
                        title={""}
                        placeholderVisible
                        isCalendarOpened={
                          openedListName === `staff.${ind}.${title}`
                        }
                        setIsCalendarOpened={() => {
                          changeOpenedWindows(`staff.${ind}.${title}`);
                        }}
                        onChange={(value) => {
                          setDuplicateError(title, value, ind);
                        }}
                        disabled={
                          title === "edu_date_start" && !row["holding_edu"]
                        }
                        className={{
                          container: styles.datePickerCustom,
                          popper: styles.datePickerPopper,
                          input: styles.datePickerInput
                        }}
                        withoutError
                      />
                    )}
                    <StaffImportTableError
                      error={errors.staff?.[ind]?.[title]}
                    />
                    {isDbDuplicate && (
                      <StaffImportTableBodyDuplicateError
                        index={ind}
                        title={title}
                      />
                    )}

                    {isInnerDuplicate && !isDbDuplicate && (
                      <StaffImportTableBodyDuplicateError
                        index={ind}
                        title={title}
                        innerDoubles
                      />
                    )}
                  </td>
                );
              case "training_date_start":
                return (
                  <td
                    key={title}
                    className={`${getModifiedCell()} ${getErrorCell()}`}
                  >
                    <DatePickerField
                      name={`staff.${ind}.${title}`}
                      title={""}
                      placeholderVisible
                      isCalendarOpened={
                        openedListName === `staff.${ind}.${title}`
                      }
                      setIsCalendarOpened={() => {
                        changeOpenedWindows(`staff.${ind}.${title}`);
                      }}
                      onChange={(value) => {
                        setDuplicateError(title, value, ind);
                      }}
                      maxDate={
                        new Date(
                          Date.parse(
                            values.staff[ind]?.training_date_finish as string
                          )
                        )
                      }
                      className={{
                        container: styles.datePickerCustom,
                        popper: styles.datePickerPopper,
                        input: styles.datePickerInput
                      }}
                      withoutError
                    />
                    <StaffImportTableError
                      error={errors.staff?.[ind]?.[title]}
                    />
                    {isDbDuplicate && (
                      <StaffImportTableBodyDuplicateError
                        index={ind}
                        title={title}
                      />
                    )}

                    {isInnerDuplicate && !isDbDuplicate && (
                      <StaffImportTableBodyDuplicateError
                        index={ind}
                        title={title}
                        innerDoubles
                      />
                    )}
                  </td>
                );
              case "birthday":
              case "pasp_date":
              case "training_date_finish":
                return (
                  <td
                    key={title}
                    className={`${getModifiedCell()} ${getErrorCell()}`}
                  >
                    <DatePickerField
                      name={`staff.${ind}.${title}`}
                      title={""}
                      placeholderVisible
                      isCalendarOpened={
                        openedListName === `staff.${ind}.${title}`
                      }
                      setIsCalendarOpened={() => {
                        changeOpenedWindows(`staff.${ind}.${title}`);
                      }}
                      onChange={(value) => {
                        setDuplicateError(title, value, ind);
                      }}
                      minDate={
                        title === "training_date_finish"
                          ? new Date(
                              Date.parse(
                                values.staff[ind]?.training_date_start as string
                              )
                            )
                          : undefined
                      }
                      className={{
                        container: styles.datePickerCustom,
                        popper: styles.datePickerPopper,
                        input: styles.datePickerInput
                      }}
                      withoutError
                    />
                    <StaffImportTableError
                      error={errors.staff?.[ind]?.[title]}
                    />
                    {isDbDuplicate && (
                      <StaffImportTableBodyDuplicateError
                        index={ind}
                        title={title}
                      />
                    )}

                    {isInnerDuplicate && !isDbDuplicate && (
                      <StaffImportTableBodyDuplicateError
                        index={ind}
                        title={title}
                        innerDoubles
                      />
                    )}
                  </td>
                );
              case "pasp_place":
              case "propisk":
              case "living_adress":
              case "birth_place":
                return (
                  <td
                    key={title}
                    className={`${
                      styles.textareaBlock
                    } ${getModifiedCell()} ${getErrorCell()}`}
                  >
                    <Textarea
                      name={`staff.${ind}.${title}`}
                      value={row[title] as string}
                      placeholder={staffImportStore.tableCols[title]?.title}
                      onChange={(e) => {
                        handleChange(e);
                        setDuplicateError(title, e.target.value, ind);
                      }}
                      onBlur={handleBlur}
                      withoutErrorText
                      withoutCounter
                    />
                    <StaffImportTableError
                      error={errors.staff?.[ind]?.[title]}
                    />
                    {isDbDuplicate && (
                      <StaffImportTableBodyDuplicateError
                        index={ind}
                        title={title}
                      />
                    )}

                    {isInnerDuplicate && !isDbDuplicate && (
                      <StaffImportTableBodyDuplicateError
                        index={ind}
                        title={title}
                        innerDoubles
                      />
                    )}
                  </td>
                );
              case "snils":
              case "inn":
              case "pasp_code":
                return (
                  <td
                    key={title}
                    className={`${
                      styles.inputBlock
                    } ${getModifiedCell()} ${getErrorCell()}`}
                  >
                    <NumberFormat
                      format={formatDict[title]}
                      mask={formatDict[title].includes(" ") ? "_" : ""}
                      name={`staff.${ind}.${title}`}
                      value={row[title] as string}
                      placeholder={staffImportStore.tableCols[title]?.title}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e);
                        setDuplicateError(title, e.target.value, ind);
                      }}
                      onBlur={handleBlur}
                      customInput={Input}
                    />
                    {isDbDuplicate && (
                      <StaffImportTableBodyDuplicateError
                        index={ind}
                        title={title}
                      />
                    )}

                    {isInnerDuplicate && !isDbDuplicate && (
                      <StaffImportTableBodyDuplicateError
                        index={ind}
                        title={title}
                        innerDoubles
                      />
                    )}
                  </td>
                );

              default:
                if (!title.includes("phone")) {
                  return (
                    <td
                      key={title}
                      className={classNames(
                        styles.inputBlock,
                        {
                          [styles.patronymic]: title === "patronymic",
                          [styles.patronymic__shadow]:
                            title === "patronymic" &&
                            menuStore.scroll["scrollLeft"]
                        },
                        [getModifiedCell(), getErrorCell()]
                      )}
                    >
                      <Input
                        name={`staff.${ind}.${title}`}
                        onChange={(e) => {
                          handleChange(e);
                          setDuplicateError(title, e.target.value, ind);
                        }}
                      />
                      <StaffImportTableError
                        error={errors.staff?.[ind]?.[title]}
                      />
                      {isDbDuplicate && (
                        <StaffImportTableBodyDuplicateError
                          index={ind}
                          title={title}
                        />
                      )}

                      {isInnerDuplicate && !isDbDuplicate && (
                        <StaffImportTableBodyDuplicateError
                          index={ind}
                          title={title}
                          innerDoubles
                        />
                      )}
                    </td>
                  );
                } else {
                  return (
                    <td
                      key={title}
                      className={`${
                        styles.phoneBlock
                      } ${getModifiedCell()} ${getErrorCell()}`}
                    >
                      {row[title] ? (
                        <PhoneInput
                          name={`staff.${ind}.${title}`}
                          onChange={(e, countryCallingCode) => {
                            const validValue = `${countryCallingCode}${e.target.value
                              .replaceAll("-", "")
                              .replaceAll(" ", "")
                              .replaceAll("_", "")
                              .replaceAll("(", "")
                              .replaceAll(")", "")}`;
                            setDuplicateError(title, validValue, ind);
                          }}
                          onClear={() => {
                            setFieldValue(`staff.${ind}.${title}`, ""); // Сбрасываем значение в Formik
                            setFieldTouched(`staff.${ind}.${title}`, false); // Убираем состояние "изменено"

                            setTimeout(() => {
                              setDuplicateError(title, "", ind); // Запускаем проверку дублей после обновления состояния
                            }, 0);
                          }}
                          className={{
                            flag: styles.phoneBlockTop,
                            callingCode: styles.phoneBlockCode,
                            clearBtn: styles.phoneBlockTop,
                            error: styles.phoneBlockError,
                            input: styles.phoneBlockInput,
                            select: styles.phoneBlockSelect
                          }}
                        />
                      ) : (
                        <PhoneInput
                          name={`staff.${ind}.${title}`}
                          className={{
                            flag: styles.phoneBlockTop,
                            callingCode: styles.phoneBlockCode,
                            clearBtn: styles.phoneBlockTop,
                            error: styles.phoneBlockError,
                            input: styles.phoneBlockInput,
                            select: styles.phoneBlockSelect
                          }}
                        />
                      )}
                      <StaffImportTableError
                        error={errors.staff?.[ind]?.[title]}
                      />
                      {isDbDuplicate && (
                        <StaffImportTableBodyDuplicateError
                          index={ind}
                          title={title}
                        />
                      )}

                      {isInnerDuplicate && !isDbDuplicate && (
                        <StaffImportTableBodyDuplicateError
                          index={ind}
                          title={title}
                          innerDoubles
                        />
                      )}
                    </td>
                  );
                }
            }
          })}
        </tr>
      ))}
    </>
  );
};

export default observer(StaffImportTableBody);

function renderHover(isDateHidden, ind) {
  if (isDateHidden.includes(ind)) {
    return {
      "data-no-hover": true
    };
  }
  return {};
}
