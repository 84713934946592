import { observer } from "mobx-react-lite";
import { Button, ButtonTheme } from "shared/ui/Button";
import StatusIcon from "shared/ui/StatusIcon";

const StaffImportUploadFileDownload = () => {
  /**
   * Скачивает файл "import.xlsx" из корня проекта
   *
   *
   * Сейчас загружаем статичный файл excel
   * Для формирования нового файла здесь нужно применить функцию staffImportStore.handleDownload
   * Затем сделать правки в самом файле: для всех столбцов, для которых есть справочники (они на втором листе)
   * нужно настроить проверку данных (выпадающие списки):
   * - Для начала нужно показать вторую строку табоицы (ПКМ между первой и третьей строкой слева - Показать, может сработать не с первого раза)
   * - Выделить весь столбец, для которого нужно применить правило (добавить выпадающий список)
   * - Данные => Проверить данные => Появится окно, кикнуть ОК, затем ещё раз ОК
   * - Затем в этом столбце выделить только первые две ячейки (название и ключ)
   * - Снова заходим в Данные => Проверить данные => Выбрать в окне "Условия проверки" "Тип данных" - Любые значение
   * - Повторить для всех столбцов с выпадающими списками
   * - Скрыть вторую строку с  ключами
   * - На втором листе скрыть второй столбец 
   * - Защитить второй лист, заблокировать любые действия (проставить checkbox)
   * - Пароль - developer
   * - Готовый файл положить в public/docs
   */
  const handleDownloadStatic = () => {
    const link = document.createElement("a");
    link.href = "/docs/import.xlsx"; // Путь к файлу в папке public
    link.download = "Импорт_людей_в_ПО.xlsx";
    link.click();
  };

  return (
    <Button
      id="StaffImportUpload_download_xlsx"
      onClick={handleDownloadStatic}
      theme={ButtonTheme.SECONDARY}
    >
      <StatusIcon icon="iconexcel" /> Cкачать шаблон Excel
    </Button>
  );
};

export default observer(StaffImportUploadFileDownload);
